import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import LaddaButton from "../../components/buttons/laddaButton";
import TabPanel from "@mui/lab/TabPanel";
import TabComponent from "../../components/tabComponent";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import "./styles.css";
import { icons } from "../../constants";
import SimpleTable from "../../components/tables/simpleTable";
import ModalComponent from "../../components/modals";
import { useForm } from "react-hook-form";
import TextArea from "../../components/textArea";
import { getCookie } from "../../utils/getCookie";
import GoalPaiTab from "./goalPaiTab/goalPaiTab";
import FirstActionPaiTab from "./firstActionTab/firstActionPaiTab";
import SecondActionPaiTab from "./secondActionTab/secondActionPaiTab";
import OdservationsPaiTab from "./observationsPaiTab/observationsPaiTab";
import {
  callApiAsync,
  getCallPaiData,
  postUpdateField,
} from "../../services/apiService";

const Pai_360 = () => {
  const userSesion = getCookie("sesion");
  let { state } = useLocation();

  const [openAddComment, setOpenAddComment] = useState(false);
  const [paiData, setPaiData] = useState([]);
  const [editGoal, setEditGoal] = useState(false);
  const [editAction, setEditAction] = useState(false);
  const [editActionII, setEditActionII] = useState(false);
  const [infoCompetencia, setInfoCompetencia] = useState([]);
  const [commentData, setcommentData] = useState({});
  const [fielToUpdate, setFielToUpdate] = useState({});
  const [defaultFormData, setdefaultFormData] = useState({});
  const { control, getValues, reset } = useForm();
  const [currentName, setCurrentName] = useState("");

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: "10px",
    borderTop: `0`,
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    fontFamily: "Lato",
  }));
  {
    /* ------------------------------ END POINTS ------------------------------ */
  }

  const getPaiData = async () => {
    callApiAsync(
      () => getCallPaiData(userSesion.id_usuario),
      (data) => {
        setCurrentName(data.userName)
        setPaiData(data.competencyData);
      }
    );
  };

  const updateField = async (id, field, text) => {
    const objRequest = {
      competenciaId: id,
      field: field,
      text: text,
    };
    callApiAsync(() => postUpdateField(objRequest));
  };

  useEffect(() => {
    getPaiData();
  }, []);

  const observationsBurnData = [
    "Las metas que se exija, deben ser retantes pero no imposibles de lograr.",
    "Definir un plan de acción coherente y organizado para el logro de la meta.",
    "Definir una fecha se seguimiento y finalización.",
    "Establecer las personas y los recursos que necesita para lograr su objetivo.",
    "Identificar las barreras que posiblemente le impedirían llegar a su meta.",
  ];

  const observationColumns = [
    { field: "name", header: "Nombre" },
    { field: "comment", header: "Comentario" },
    { field: "date", header: "Fecha " },
    { field: "actionButtons", header: "", columnType: "editButtonsColumn" },
  ];

  const FormModal = ({
    open,
    handleClose,
    title,
    onSubmit,
    fieldName1,
    fieldName2,
  }) => {
    let formContent;
    switch (fieldName1) {
      case "goal":
        formContent = (
          <form className="VContainer">
            <TextArea
              name={fieldName1}
              control={control}
              label="Meta:"
              defaultValue={defaultFormData.goal}
              rows={6}
            />
            <TextArea
              name={fieldName2}
              control={control}
              label="Seguimiento por:"
              defaultValue={defaultFormData.following}
              rows={1}
            />
          </form>
        );
        break;
      case "newComment":
        formContent = (
          <form className="VContainer">
            <TextArea
              name={fieldName1}
              control={control}
              label="Comentario:"
              defaultValue={defaultFormData.comment}
              rows={6}
            />
          </form>
        );
        break;
      default:
        formContent = (
          <form className="VContainer">
            <TextArea
              name={fieldName1}
              control={control}
              label="Acciones:"
              defaultValue={defaultFormData.action}
              rows={6}
            />
            <TextArea
              name={fieldName2}
              control={control}
              label="Indicador de resultado:"
              defaultValue={defaultFormData.indicator}
              rows={1}
            />
          </form>
        );
    }

    return (
      <ModalComponent
        open={open}
        handleClose={handleClose}
        title={title}
        actions={
          <>
            <LaddaButton variant="contained" onClick={onSubmit}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar</p>
              </div>
            </LaddaButton>
            <LaddaButton variant="contained" onClick={handleClose}>
              <div className="HContainer align-center">
                <p className="button_text">Cerrar</p>
              </div>
            </LaddaButton>
          </>
        }
      >
        {formContent}
      </ModalComponent>
    );
  };

  {
    /* ------------------------------ FORMS FUNTIONS ------------------------------ */
  }

  const handleClose = () => {
    setOpenAddComment(false);
    setEditGoal(false);
    setEditAction(false);
    setEditActionII(false);
    setcommentData({});
    setFielToUpdate({});
    setdefaultFormData({});
    reset(defaultFormData);
  };

  const firstCompetenceTabData = [
    {
      name: "Metas",
      id: "0",
      content: <GoalPaiTab data={paiData[0]} getPaiData={getPaiData} />,
    },
    {
      name: "Acción 1",
      id: "1",
      content: <FirstActionPaiTab data={paiData[0]} getPaiData={getPaiData} />,
    },
    {
      name: "Acción 2",
      id: "2",
      content: <SecondActionPaiTab data={paiData[0]} getPaiData={getPaiData} />,
    },
    {
      name: "Observaciones",
      id: "3",
      content: <OdservationsPaiTab data={paiData[0]} getPaiData={getPaiData} />,
    },
  ];

  const secondCompetenceTabData = [
    {
      name: "Metas",
      id: "0",
      content: <GoalPaiTab data={paiData[1]} getPaiData={getPaiData} />,
    },
    {
      name: "Acción 1",
      id: "1",
      content: <FirstActionPaiTab data={paiData[1]} getPaiData={getPaiData} />,
    },
    {
      name: "Acción 2",
      id: "2",
      content: <SecondActionPaiTab data={paiData[1]} getPaiData={getPaiData} />,
    },
    {
      name: "Observaciones",
      id: "3",
      content: <OdservationsPaiTab data={paiData[1]} getPaiData={getPaiData}/>,
    },
  ];

  const PanelComponent = ({ data }) => {
    const followingArray = data.following
      ? data.following.split(",").map((item) => item.trim())
      : [];

    const tabs = [
      {
        name: "Metas",
        id: "0",
        content: (
          <CustomTabPanel value="0">
            <h2 className="gm-title">
              ¿Qué le gustaría lograr al señor {data.userName}?
            </h2>
            <div className="j-space-between">
              <p className="tab-content-text">{data.goal}</p>
              <LaddaButton
                backgroundColor={"#FFFF"}
                color={"#6a6ad8"}
                onClick={() => {
                  setEditGoal(true);
                  setFielToUpdate(data.id);
                  setdefaultFormData({
                    goal: data.goal,
                    following: data.following,
                  });
                }}
              >
                <img className="btn-icon-40" src={icons.editBlue} alt="icon" />
              </LaddaButton>
            </div>
            <h2 className="gm-title">Seguimiento por:</h2>
            <p className="tab-content-text">
              {followingArray.map((x) => (
                <p>{x}</p>
              ))}
            </p>
          </CustomTabPanel>
        ),
      },
      {
        name: "Acción 1",
        id: "1",
        content: (
          <CustomTabPanel value="1">
            <h2 className="gm-title">
              ¿Qué acciones estaría dispuesto a emprender para lograr esto?
            </h2>
            <div className="j-space-between">
              <p className="tab-content-text">{data.action1}</p>
              <LaddaButton
                backgroundColor={"#FFFF"}
                color={"#6a6ad8"}
                onClick={() => {
                  setEditAction(true);
                  setFielToUpdate(data.id);
                  setdefaultFormData({
                    action: data.action1,
                    indicator: data.indicator1,
                  });
                }}
              >
                <img className="btn-icon-40" src={icons.editBlue} alt="icon" />
              </LaddaButton>
            </div>
            <h2 className="gm-title">Indicador de resultado:</h2>
            <p>{data.indicator1}</p>
          </CustomTabPanel>
        ),
      },
      {
        name: "Acción 2",
        id: "2",
        content: (
          <CustomTabPanel value="2">
            <h2 className="gm-title">
              ¿Qué acciones estaría dispuesto a emprender para lograr esto?
            </h2>
            <div className="j-space-between">
              <p className="tab-content-text">{data.action2}</p>
              <LaddaButton
                backgroundColor={"#FFFF"}
                color={"#6a6ad8"}
                onClick={() => {
                  setEditActionII(true);
                  setFielToUpdate(data.id);
                  setdefaultFormData({
                    action: data.action2,
                    indicator: data.indicator2,
                  });
                }}
              >
                <img className="btn-icon-40" src={icons.editBlue} alt="icon" />
              </LaddaButton>
            </div>

            <h2 className="gm-title">Indicador de resultado:</h2>
            <p>{data.indicator2}</p>
          </CustomTabPanel>
        ),
      },
      {
        name: "Observaciones",
        id: "3",
        content: (
          <CustomTabPanel value="3">
            <h2 className="gm-title">Observaciones</h2>
            <SimpleTable
              data={data.tableData}
              columns={observationColumns}
              scrollHeight={"300px"}
              buttons={[
                {
                  type: "icon",
                  icon: icons.editBlue,
                  onClick: (rowData) => {
                    setcommentData({
                      id: data.id,
                      commentspaiId: rowData.id,
                    });
                    setdefaultFormData({
                      comment: rowData.comment,
                    });
                    setOpenAddComment(true);
                  },
                },
              ]}
            />
            <div className="HContainer j-center" style={{ marginTop: "20px" }}>
              <LaddaButton
                variant="contained"
                onClick={() => {
                  setOpenAddComment(true);
                  setcommentData({
                    id: data.id,
                    commentspaiId: 0,
                  });
                }}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Nuevo Comentario</p>
                </div>
              </LaddaButton>
            </div>
          </CustomTabPanel>
        ),
      },
    ];

    return (
      <div className="table-container">
        <div className="HContainer j-space-between bm-30">
          <h1 className="progress-label-title">{data.competencia}</h1>
          <div className="header_item_container">
            <div
              className="HContainer align-center"
              style={{ margin: "0 15px" }}
            >
              <p className="header_item_text">Fecha de Inicio: </p>
              <span style={{ color: "black", marginLeft: 10 }}>
                {data.starDate}
              </span>
            </div>
            <div
              className="HContainer align-center"
              style={{ margin: "0 15px" }}
            >
              <p className="header_item_text">Fecha de seguimiento: </p>
              <span style={{ color: "black", marginLeft: 10 }}>
                {data.endDate}
              </span>
            </div>
            <div
              className="HContainer align-center"
              style={{ margin: "0 15px" }}
            >
              <p className="header_item_text">Último seguimiento: </p>
              <span style={{ color: "black", marginLeft: 10 }}>
                {data.updateDate}
              </span>
            </div>
          </div>
        </div>
        <TabComponent panels={tabs} />
      </div>
    );
  };

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1 className="page-title">PAI 360° -{currentName}</h1>
        <div className="HContainer">
          <div className="HContainer">
            <Link to="/superior_rol_dashboard">
              <LaddaButton
                variant="contained"
                color={"black"}
                backgroundColor={"#F4D658"}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Dashboard</p>
                </div>
              </LaddaButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="table-container">
        <h1 className="progress-label-title">Autoevaluación de Competencias</h1>

        <p className="li_text_item">
          El plan de acción individual es un instrumento que permite enfocar sus
          planes de desarrollo frente a las habilidades desarrolladas en el
          proceso de entrenamiento.
        </p>

        <p className="li_text_item">
          Para realizar un PAI exitoso, tenga en cuenta los siguientes aspectos:
        </p>
        <ul>
          {observationsBurnData.map((item) => (
            <li className="li_item_360" key={item}>
              <p className="li_text_item">{item}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className="table-container">
        <div className="HContainer j-space-between">
          <p className="progress-label-title">
            {paiData && paiData.length > 0
              ? paiData[0].competencyName || ""
              : ""}
          </p>
          <div className="HContainer j-space-between align-center">
            <>
              <p className="gm-title-s">Fecha de Inicio:</p>
              <p className="pai-date-text">
                {paiData && paiData.length > 0
                  ? paiData[0].startDate || ""
                  : ""}
              </p>
            </>

            <>
              <p className="gm-title-s">Último seguimiento:</p>
              <p className="pai-date-text">
                {paiData && paiData.length > 0
                  ? paiData[0].updateDate || ""
                  : ""}
              </p>
            </>
          </div>
        </div>
        <TabComponent panels={firstCompetenceTabData} />
      </div>

      <div className="table-container">
        <div className="HContainer j-space-between">
          <p className="progress-label-title">
            {" "}
            {paiData && paiData.length > 1
              ? paiData[1].competencyName || ""
              : ""}
          </p>
          <div className="HContainer j-space-between align-center">
            <>
              <p className="gm-title-s">Fecha de Inicio:</p>
              <p className="pai-date-text">
                {paiData && paiData.length > 1
                  ? paiData[1].startDate || ""
                  : ""}
              </p>
            </>
            <>
              <p className="gm-title-s">Último seguimiento:</p>
              <p className="pai-date-text">
                {paiData && paiData.length > 1
                  ? paiData[1].updateDate || ""
                  : ""}
              </p>
            </>
          </div>
        </div>
        <TabComponent panels={secondCompetenceTabData} />
      </div>

      {infoCompetencia.map((x) => (
        <PanelComponent key={x.id} data={x} />
      ))}
    </div>
  );
};

export default Pai_360;
