import React, { useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import { addProtocolActivity, callApiAsync, getProtocolActivitySelect } from "../../../services/apiService";
import ToastNotification from "../../../components/toastNotification";

const validationSchema = yup.object().shape({
  newActivity: yup
    .string()
    .required("Este campo es obligatorio")
    .min(1, "Este campo no puede estar vacío")
});

const AddProtocolActivity = ({ open, close, getProtocolActivities }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => {
    reset();
    close();
  };

  const onSubmit = (formData) => {
    const newActivity = formData;
    callApiAsync(
        () => addProtocolActivity(newActivity),
        () => {
            getProtocolActivities()
        }
      );
  };

  return (
    <div>
      <ModalComponent
        open={open}
        title="Agregar nueva actividad"
        handleClose={handleClose}
      >
        <ToastNotification
          severity="warn"
          summary={toastMessage}
          detail=""
          showToast={showToast}
          setShowToast={setShowToast}
        />
        <div className="HContainer">
          <form className="VContainer align-center m-20">
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <p className="create-label">Nueva actividad:</p>
                <FormTextField
                  name="newActivity"
                  control={control}
                  error={errors.newActivity}
                />
              </Grid>
            </Grid>
            <LaddaButton
              width={"180px"}
              fontSize={"12px"}
              height={"32px"}
              variant="contained"
              margin={"20px 0"}
              onClick={handleSubmit(onSubmit)}
            >
              <p className="button_text">Crear actividad</p>
            </LaddaButton>
          </form>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AddProtocolActivity;
