import React from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleTable from "../../components/tables/simpleTable";
import { useForm } from "react-hook-form";
import { getDateObj } from "../../utils/dateUtils";
import { columnChartOptions } from "../../components/highchartsComponent/charts/columns";
import HighchartsComponent from "../../components/highchartsComponent";
import SearchBar from "../../components/searchBar";
import SimpleSelect from "../../components/selects/simpleSelect";
import { months } from "../../constants/selectData";
import { useLocation } from "react-router-dom";
import { Grid, styled } from "@mui/material";
import "./styles.css";
import PillComponent from "../../components/pill";
import { actualYearSelect } from "../../services/dateService";

const burnDataChard = {
  title: "Presupuesto de Venta vs. Venta Real",
  subtitle: "",
  chard: columnChartOptions,
  yAxis: "Miles de Millones",
  series: [
    {
      name: "Suma de Presupuesto_Valores",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
    },
    {
      type: "line",
      step: "center",
      name: "Suma de Ventas Real",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
      marker: {
        lineWidth: 2,
        lineColor: "#6A6AD8",
        fillColor: "white",
      },
    },
  ],
  categories: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
};

const dataTable = [
  {
    id: "0",
    clientList: "AUDIFARMA SA",
    salesUnit: "5",
    salesValue: "3.270.000",
  },
  {
    id: "1",
    clientList: "AVIDANTI SAS",
    salesUnit: "6",
    salesValue: "3.924.000",
  },
  {
    id: "2",
    clientList: "BONNE SANTE GROUP SAS",
    salesUnit: "1",
    salesValue: "407.200",
  },
  {
    id: "3",
    clientList: "CAFAM CAJA DE COMPENSACION FAMILIAR",
    salesUnit: "0.72",
    salesValue: "127.670,4",
  },
  {
    id: "4",
    clientList: "CAJA DE COMPENSACION FAMILIAR COMPENSAR",
    salesUnit: "0,18",
    salesValue: "437.253,3",
  },
  {
    id: "5",
    clientList: "CAJA DE COMPENSACION FAMILIAR DE FENALCO COMFENALCO QUINDIO",
    salesUnit: "0,18",
    salesValue: "437.253,3",
  },
  {
    id: "6",
    clientList: "CAJA DE COMPENSACION FAMILIAR DEL CHOCO",
    salesUnit: "0,18",
    salesValue: "437.253,3",
  },
];

const topClientsTableColums = [
  { field: "clientList", header: "Listado de Clientes Compradores" },
  { field: "salesUnit", header: "Unidades Ventas" },
  { field: "salesValue", header: "Valor Ventas" },
];

const ProductDetails = () => {
  const { control, getValues } = useForm();
  const dateObj = getDateObj();

  const location = useLocation();
  const { rowData } = location.state || {};


  const handleChange = () => {
    const formData = getValues();
  };

  const GridStyled = styled(Grid)(({ theme }) => ({
    margin: "0",
    padding: "0",
    "& .MuiGrid-item": {
      padding: "0",
    },
  }));

  return (
    <div className="App">
      <BreadCrumbComp />
      <h1 className="page-title">DETALLE DEL PRODUCTO</h1>
      <div className="white-space" />
      <div className="table-container">
        <Grid container spacing={2}>
          <GridStyled item xs={12} md={6}>
            <p style={{ color: "#6A6AD8", fontWeight: "700", margin: 0 }}>
              Nombre del Producto:{" "}
              <span className="product-details-data-text">
                IALURIL PREFILL JER/PRELLENADA 50ML IALUADAPTER
              </span>
            </p>
          </GridStyled>
          <GridStyled item xs={12} md={6}>
            <p style={{ color: "#6A6AD8", fontWeight: "700", margin: 0 }}>
              N° del Producto:{" "}
              <span className="product-details-data-text">0080001000003</span>
            </p>
          </GridStyled>

          <GridStyled item xs={12} md={6}>
            <p style={{ color: "#6A6AD8", fontWeight: "700", margin: 0 }}>
              Referencia:{" "}
              <span className="product-details-data-text">
                EXP20079776_INVIMVA 2014DM-0012306
              </span>
            </p>
          </GridStyled>
          <GridStyled item xs={12} md={6}>
            <p style={{ color: "#6A6AD8", fontWeight: "700", margin: 0 }}>
              Marca:{" "}
              <span className="product-details-data-text">SERANEST PHARMA</span>
            </p>
          </GridStyled>

          <GridStyled item xs={12} md={6}>
            <p
              style={{
                color: "#6A6AD8",
                fontWeight: "700",
                margin: 0,
                display: "flex",
              }}
            >
              Línea:{" "}
              <span
                style={{ color: "#404040", marginLeft: 10, fontWeight: "400" }}
              >
                <PillComponent text="Urología" color="#F2EA77" />
              </span>
            </p>
          </GridStyled>
          <GridStyled item xs={12} md={6}>
            <p
              style={{
                color: "#6A6AD8",
                fontWeight: "700",
                margin: 0,
                display: "flex",
              }}
            >
              Grupo:{" "}
              <span
                style={{ color: "#404040", marginLeft: 10, fontWeight: "400" }}
              >
                <PillComponent text="Urología" color="#DFDFF7" />
              </span>
            </p>
          </GridStyled>
        </Grid>
        <div className="white-space" />
        <div>
          <HighchartsComponent
            options={burnDataChard.chard(
              burnDataChard.title,
              burnDataChard.subtitle,
              burnDataChard.yAxis,
              burnDataChard.series,
              burnDataChard.categories
            )}
          />
        </div>
        <div className="white-space" />
        <div className="HContainer">
          <form
            style={{ display: "flex", display: "flex", alignItems: "center" }}
          >
            <SearchBar
              name="search"
              control={control}
              onSearch={handleChange}
            />
            <SimpleSelect
              name="month"
              control={control}
              label="Mes"
              options={months}
              onChange={handleChange}
              defaultValue={dateObj.month}
            />
            <SimpleSelect
              name="quarter"
              control={control}
              label="Trimestre"
              options={months}
              onChange={handleChange}
              defaultValue={dateObj.month}
            />
            <SimpleSelect
              name="year"
              control={control}
              label="Año"
              options={actualYearSelect}
              onChange={handleChange}
              defaultValue={dateObj.year}
            />
          </form>
        </div>
        <div className="white-space" />
        <SimpleTable
          data={dataTable}
          columns={topClientsTableColums}
          // buttons={[
          //   {
          //     type: "icon",
          //     icon: icons.eye,
          //     onClick: () => navigate("/dashboard/dofa"),
          //   },
          // ]}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
