import React, { useEffect, useState } from "react";
import HighchartsComponent from "../../components/highchartsComponent";
import { Grid } from "@mui/material";
import SimpleSelect from "../../components/selects/simpleSelect";
import { fourMonthPeriod, months } from "../../constants/selectData";
import { useForm } from "react-hook-form";
import { getCookie } from "../../utils/getCookie";
import {
  getActualfourMonthPeriod,
  getCurrentMonth,
  getCurrentYear,
  getFourMonthPeriodName,
} from "../../utils/dateUtils";
import {
  callApiAsync,
  getCallDofaGraph,
  getCallOpportunityGraph,
  getCallRiskResumeGraph,
} from "../../services/apiService";
import { actualYearSelect } from "../../services/dateService";

const GraphSection = ({ filters, typeGraph, title }) => {
  const getAxisX = () => {
    switch (filters) {
      case "dofa":
        return "Cantidad";
      case "risk":
        return "Riesgos";
      case "opportunity":
        return "Oportunidades";
      default:
        return "";
    }
  };

  const [graphSeries, setGraphSeries] = useState([]);
  const [graphCategories, setCategories] = useState([]);

  const [extraData, setExtraData] = useState({
    month: getCurrentMonth(),
    year: getCurrentYear(),
  });

  const { control, getValues, setValue } = useForm();
  const userSesion = getCookie("sesion");

  const handleChange = async () => {
    const formData = getValues();
    setExtraData(formData);
    getGraphData(formData.year, formData.month, formData.fourMonthPeriod);
  };

  const getFilters = (type) => {
    switch (type) {
      case "dofa":
      case "risk":
        return (
          <>
            <div className="mr-10">
              <Grid item xs={12} md={12}>
                <SimpleSelect
                  name="fourMonthPeriod"
                  control={control}
                  label="Primer Cuatrimestre"
                  options={fourMonthPeriod}
                  onChange={handleChange}
                  // defaultValue={getDateObj.month}
                />
              </Grid>
            </div>
            <Grid item xs={12} md={12}>
              <div className="mr-10">
                <SimpleSelect
                  name="year"
                  control={control}
                  label="Año"
                  options={actualYearSelect}
                  onChange={handleChange}
                  // defaultValue={getDateObj.year}
                />
              </div>
            </Grid>
          </>
        );
      case "opportunity":
        return (
          <>
            <div className="mr-10">
              <Grid item xs={12} md={12}>
                <SimpleSelect
                  name="month"
                  control={control}
                  label="Mes"
                  options={months}
                  onChange={handleChange}
                  // defaultValue={getDateObj.month}
                />
              </Grid>
            </div>
            <Grid item xs={12} md={12}>
              <div className="mr-10">
                <SimpleSelect
                  name="year"
                  control={control}
                  label="Año"
                  options={actualYearSelect}
                  onChange={handleChange}
                  // defaultValue={getDateObj.year}
                />
              </div>
            </Grid>
          </>
        );
    }
  };

  const getGraphData = async (
    yearFilter,
    monthFilter,
    fourMonthPeriodFilter
  ) => {
    let graphFn = null;

    switch (filters) {
      case "dofa":
        graphFn = () =>
          getCallDofaGraph(
            userSesion.id_usuario,
            yearFilter,
            fourMonthPeriodFilter
          );
        break;
      case "risk":
        graphFn = () =>
          getCallRiskResumeGraph(
            userSesion.id_usuario,
            yearFilter,
            fourMonthPeriodFilter
          );
        break;
      case "opportunity":
        graphFn = () =>
          getCallOpportunityGraph(
            userSesion.id_usuario,
            yearFilter,
            monthFilter
          );
        break;
      default:
        console.error("Invalid type:", filters);
        return;
    }
    callApiAsync(graphFn, (data) => {
      setGraphSeries(data.series);
      setCategories(data.categories);
    });
  };

  useEffect(() => {
    setValue("year", getCurrentYear());
    setValue("month", getCurrentMonth());
    setValue("fourMonthPeriod", getActualfourMonthPeriod());
    setCategories(getFourMonthPeriodName(getActualfourMonthPeriod()));
    getGraphData(
      getCurrentYear(),
      getCurrentMonth(),
      getActualfourMonthPeriod()
    );
    // filterData(getCurrentYear(), getCurrentMonth());
  }, []);

  return (
    <div>
      <br className="white-space-l" />

      <div className="HContainer j-space-between">
        <h2 className="gm-title">{title}</h2>

        <form style={{ display: "flex" }}>{getFilters(filters)}</form>
      </div>

      <Grid justifyContent="center" container item xs={12} md={12}>
        <HighchartsComponent
          options={typeGraph("", "", getAxisX(), graphSeries, graphCategories)}
        />
      </Grid>
    </div>
  );
};

export default GraphSection;
