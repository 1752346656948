import React from "react";
import BreadCrumbComp from "../../../components/breadCrumb";
import LaddaButton from "../../../components/buttons/laddaButton";
import { months } from "../../../constants/selectData";
import { useForm } from "react-hook-form";
import "../styles.css";
import { Grid } from "@mui/material";
import FormSelect from "../../../components/selects/formSelect";
import FormTextField from "../../../components/textField/formTextField";
import FormCalendar from "../../../components/calendar/formCalendar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormRadioButton from "../../../components/formRadioButton";

const validationSchema = yup.object().shape({
  type: yup
    .string()
    .notOneOf(["default"], "Este campo es obligatorio")
    .required("Este campo es obligatorio"),
  date: yup.date().required("Este campo es obligatorio"),
  engineerInCharge: yup
    .string()
    .notOneOf(["default"], "Este campo es obligatorio")
    .required("Este campo es obligatorio"),
  model: yup.string().required("Este campo es obligatorio"),
  brand: yup.string().required("Este campo es obligatorio"),
  serialNumber: yup.string().required("Este campo es obligatorio"),
  service: yup.string().required("Este campo es obligatorio"),
  team: yup.string().required("Este campo es obligatorio"),

  insitutionDestination: yup.string().required("Este campo es obligatorio"),
  destinationDirection: yup.string().required("Este campo es obligatorio"),
  city: yup.string().required("Este campo es obligatorio"),
  department: yup.string().required("Este campo es obligatorio"),
  observations: yup.string().required("Este campo es obligatorio"),
  status: yup.string().required("Este campo es obligatorio"),
});

const CreateRequest = () => {
  const {
    control: controlForm,
    getValues: getValuesForm,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleFormInput = () => {
    const formData = getValuesForm();
  };

  const options = [
    { label: "Activo", value: "active" },
    { label: "Inactivo", value: "inactive" },
  ];

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>CREAR NUEVO REQUERIMIENTO</h1>
      </div>
      <div className="table-container">
        <div className="VContainer j-space-between">
          <h1 className="title-bio-details-chard">
            Requerimiento - Consecutivo N°153131
          </h1>
          <br className="br-white-space" />
          <div>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <FormSelect
                    name="type"
                    control={controlForm}
                    formLabel="Seleccionar tipo de Requerimiento"
                    label="Requerimiento:"
                    options={months}
                    error={errors.type}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5} md={2.5}>
                  {/* <FormCalendar
                    name="date"
                    control={controlForm}
                    label="Fecha:"
                    defaultValue={null}
                    formLabel=""
                  /> */}
                  <FormCalendar
                    name="date"
                    control={controlForm}
                    label="Fecha:"
                    defaultValue={null}
                    formLabel=""
                    error={errors.date} // Pasar el error correspondiente
                  />
                </Grid>
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <FormSelect
                    name="engineerInCharge"
                    control={controlForm}
                    formLabel="Seleccionar Ingeniero"
                    label="Ingeniero a cargo:"
                    options={months}
                    error={errors.engineerInCharge}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5} md={2.5}>
                  <FormTextField
                    name="model"
                    control={controlForm}
                    formLabel=""
                    label="Modelo:"
                    options={months}
                    error={errors.model}
                  />
                </Grid>
                {/* ----------------- second row ----------------- */}
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <FormTextField
                    name="brand"
                    control={controlForm}
                    formLabel=""
                    label="Marca:"
                    options={months}
                    error={errors.brand}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5} md={2.5}>
                  <FormTextField
                    name="serialNumber"
                    control={controlForm}
                    formLabel=""
                    label="Nro. de Serie:"
                    options={months}
                    error={errors.serialNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <FormTextField
                    name="service"
                    control={controlForm}
                    formLabel=""
                    label="Servicio:"
                    options={months}
                    error={errors.service}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5} md={2.5}>
                  <FormTextField
                    name="team"
                    control={controlForm}
                    formLabel=""
                    label="Equipo:"
                    options={months}
                    error={errors.team}
                  />
                </Grid>
              </Grid>
            </form>
            {/* ----------------- geo form ----------------- */}
            <br className="br-white-space" />

            <h1 className="title-bio-details-chard">Ubicación Geográfica</h1>
            <br className="br-white-space" />

            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <FormTextField
                    name="insitutionDestination"
                    control={controlForm}
                    formLabel=""
                    label="Institución de destino:"
                    options={months}
                    error={errors.insitutionDestination}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormTextField
                    name="destinationDirection"
                    control={controlForm}
                    formLabel=""
                    label="Dirección de destino:"
                    options={months}
                    error={errors.destinationDirection}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormTextField
                    name="city"
                    control={controlForm}
                    formLabel=""
                    label="Ciudad:"
                    options={months}
                    error={errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormTextField
                    name="department"
                    control={controlForm}
                    formLabel=""
                    label="Departamento:"
                    options={months}
                    error={errors.department}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ height: "190px" }}>
                  <FormTextField
                    name="observations"
                    control={controlForm}
                    formLabel=""
                    label="Observaciones:"
                    options={months}
                    isTextArea={true}
                    error={errors.observations}
                  />
                </Grid>
              </Grid>
              <br className="br-white-space" />

              <Grid item xs={12} sm={3.5} md={3.5}>
                <FormRadioButton
                  name="status"
                  control={controlForm}
                  label="Estado:"
                  options={options}
                  error={errors.status}
                />
              </Grid>
              <br className="br-white-space" />

              <div className="HContainer j-center">
                <LaddaButton
                  variant="contained"
                  type="submit"
                  height={"32px"}
                  onClick={handleSubmit(handleFormInput)}
                  // onClick={() => handleFormInput()}
                >
                  <div className="HContainer align-center">
                    <p className="button_text">Crear Requerimiento</p>
                  </div>
                </LaddaButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRequest;
