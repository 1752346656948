import React, { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import LaddaButton from "../buttons/laddaButton";
import { icons } from "../../constants";
import "./styles.css";

const MenuButton = ({ title, icon, children, notification }) => {
  const op = useRef(null);

  return (
    <div>
      <div onClick={(e) => op.current.toggle(e)}>
        <LaddaButton
          color={"#404040"}
          variant="contained"
          height={"42px"}
          backgroundColor={"#F4D658"}
        >
          <div
            className="HContainer align-center"
            style={{ position: "relative" }}
          >
            {notification ? (
              <div className="notification-circle">{notification}</div>
            ) : (
              <div className="notification-circle bg-gray">0</div>
            )}
          </div>
          <img
            className="btn-icon"
            src={icons.notificacion}
            alt="icon"
            style={{ marginRight: "10px" }}
          />
          <p className="button_text">{title}</p>
        </LaddaButton>
      </div>

      <OverlayPanel ref={op}>
        <div style={{ padding: "10px" }}>{children}</div>
      </OverlayPanel>
    </div>
  );
};

export default MenuButton;
