import React from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleTable from "../../components/tables/simpleTable";
import { useForm } from "react-hook-form";
import { getDateObj } from "../../utils/dateUtils";
import { columnChartOptions } from "../../components/highchartsComponent/charts/columns";
import HighchartsComponent from "../../components/highchartsComponent";
import SearchBar from "../../components/searchBar";
import SimpleSelect from "../../components/selects/simpleSelect";
import { months } from "../../constants/selectData";
import "./styles.css";
import { actualYearSelect } from "../../services/dateService";

const burnDataChard = {
  title: "Presupuesto de Venta vs. Venta Real",
  subtitle: "",
  chard: columnChartOptions,
  yAxis: "Miles de Millones",
  series: [
    {
      name: "Suma de Presupuesto_Valores",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
    },
    {
      type: "line",
      step: "center",
      name: "Suma de Ventas Real",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
      marker: {
        lineWidth: 2,
        lineColor: "#6A6AD8",
        fillColor: "white",
      },
    },
  ],
  categories: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
};

const dataTable = [
  {
    id: "0",
    customerName: "ANESTESIOLOGIA VETERINARIA COLOMBIA SAS",
    budget: "10.008.000",
    sales: "240712500",
    strengths: "100",
    linesNumber: "2",
  },
  {
    id: "1",
    customerName: "ASOCIACION PROFAMILIA",
    budget: "53.550.000",
    sales: "230536500",
    strengths: "100",
    linesNumber: "1",
  },
  {
    id: "2",
    customerName: "AUDIFARMA SA",
    budget: "3.174.150.000",
    sales: "130357000",
    strengths: "100",
    linesNumber: "1",
  },
  {
    id: "3",
    customerName: "AVIDANTI SAS",
    budget: "15.135.000",
    sales: "128190000",
    strengths: "100",
    linesNumber: "2",
  },
  {
    id: "4",
    customerName: "BONNE SANTE GROUP SAS",
    budget: "17.040.000",
    sales: "240712500",
    strengths: "79",
    linesNumber: "1",
  },
  {
    id: "5",
    customerName: "CENTRO OFTALMOLOGICO OLSABE SAS",
    budget: "17.040.000",
    sales: "240712500",
    strengths: "78",
    linesNumber: "1",
  },
  {
    id: "6",
    customerName: "CIRULASER ANDES SA",
    budget: "17.040.000",
    sales: "240712500",
    strengths: "70",
    linesNumber: "1",
  },
];

const TopProducts = () => {
  const { control, getValues } = useForm();
  const dateObj = getDateObj();

  const handleChange = () => {
    const formData = getValues();
  };

  const topProductsTableColums = [
    {
      field: "customerName",
      header: "Nombre del Cliente",
      columnType: "redirection",
      url: '/clients/topProducts/details'
    },
    { field: "budget", header: "Presupuesto" },
    { field: "sales", header: "Ventas", columnType: "upDownNumber" },
    { field: "strengths", header: "Cumplimiento %", columnType: "percentageBtn" },
    { field: "linesNumber", header: "Cant. Líneas" },
  ];

  return (
    <div className="App">
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1 className="page-title">LISTADO DE PRODUCTOS</h1>
        <div className="white-space" />
      </div>
      <div>
        <HighchartsComponent
          options={burnDataChard.chard(
            burnDataChard.title,
            burnDataChard.subtitle,
            burnDataChard.yAxis,
            burnDataChard.series,
            burnDataChard.categories
          )}
        />
      </div>
      <div className="white-space" />
      <div className="HContainer">
        <form
          style={{ display: "flex", alignItems: "center" }}
        >
          <SearchBar name="search" control={control} onSearch={handleChange} />
          <SimpleSelect
            name="month"
            control={control}
            label="Mes"
            options={months}
            onChange={handleChange}
            defaultValue={dateObj.month}
          />
          <SimpleSelect
            name="quarter"
            control={control}
            label="Trimestre"
            options={months}
            onChange={handleChange}
            defaultValue={dateObj.month}
          />
          <SimpleSelect
            name="year"
            control={control}
            label="Año"
            options={actualYearSelect}
            onChange={handleChange}
            defaultValue={dateObj.year}
          />
        </form>
      </div>
      <div className="white-space" />
      <SimpleTable
        data={dataTable}
        columns={topProductsTableColums}
        // buttons={[
        //   {
        //     type: "icon",
        //     icon: icons.eye,
        //     onClick: () => navigate("/dashboard/dofa"),
        //   },
        // ]}
      />
    </div>
  );
};

export default TopProducts;
