import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../../components/breadCrumb";
import { icons } from "../../../constants";
import LaddaButton from "../../../components/buttons/laddaButton";
import { dasboardBioMedicalBurnData } from "../../../utils/burnData";
import { months } from "../../../constants/selectData";
import SimpleTable from "../../../components/tables/simpleTable";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { useForm } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import "../styles.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  formatDateText,
  formatMonth,
  formatYear,
} from "../../../utils/dateUtils";
import { actualYearSelect } from "../../../services/dateService";

const BioMedicalRequest = () => {
  const [date, setDate] = useState(null);
  const { control: controlTable, getValues: getValuesTable } = useForm();
  const navigate = useNavigate();

  const calendarValues = [
    {
      date: "2024-05-09T05:00:00.000Z",
      event: "Requerimiento de Mantenimiento previsto A",
    },
    {
      date: "2024-05-14T05:00:00.000Z",
      event: "Requerimiento de Mantenimiento previsto B",
    },
    {
      date: "2024-05-20T05:00:00.000Z",
      event: "Requerimiento de Mantenimiento previsto C",
    },
    {
      date: "2024-05-27T05:00:00.000Z",
      event: "Requerimiento de Mantenimiento previsto D",
    },
  ];

  const handleChangeTable = () => {
    const formData = getValuesTable();
  };

  useEffect(() => {
  }, [date]);

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>REQUERIMIENTOS</h1>
      </div>
      <div className="table-container">
        <div className="HContainer j-space-between">
          <h1 className="title-bio-details-chard">Requerimientos</h1>

          <LaddaButton
            variant="contained"
            // width={"200px"}
            height={"32px"}
            onClick={() => {
              navigate("/biomedicalManagementDashboard/BioMedicalRequest/createRequest", {
                state: { data: { id: 153131 } },
              });
            }}
          >
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.addPlus}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Crear Requerimiento</p>
            </div>
          </LaddaButton>
        </div>

        <div className="table-container">
          <h1 className="request-title-calendar">AGENDA DE REQUERIMIENTOS</h1>
          <div className="HContainer">
            <div className="card flex justify-content-center">
              <Calendar
                value={date}
                onChange={(e) => setDate(e.value)}
                inline
                showWeek
              />
            </div>
            <div style={{ padding: "15px" }}>
              <div className="request-title-calendar mayus">{`${formatMonth(
                date
              )} ${formatYear(date)}`}</div>
              {calendarValues.map((item) => {
                return (
                  <div style={{ margin: "15px 0" }}>
                    <span className="date-event-bold">
                      {`${formatDateText(item.date)}: `}
                      <span className="date-event-text">{item.event}</span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <br className="br-white-space" />

          <div>
            <form
              style={{
                display: "flex",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2.4} md={2.4}>
                  <SimpleSelect
                    name="type"
                    control={controlTable}
                    label="Tipo de Requerimiento"
                    options={months}
                    onChange={handleChangeTable}
                    // defaultValue={dateObj.month}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4} md={2.4}>
                  <SimpleSelect
                    name="team"
                    control={controlTable}
                    label="Equipo"
                    options={months}
                    onChange={handleChangeTable}
                    // defaultValue={dateObj.month}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4} md={2.4}>
                  <SimpleSelect
                    name="institution"
                    control={controlTable}
                    label="Institución"
                    options={months}
                    onChange={handleChangeTable}
                    // defaultValue={dateObj.month}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4} md={2.4}>
                  <SimpleSelect
                    name="enginneer"
                    control={controlTable}
                    label="Ingeniero"
                    options={months}
                    onChange={handleChangeTable}
                    // defaultValue={dateObj.month}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4} md={2.4}>
                  <SimpleSelect
                    name="calendar"
                    control={controlTable}
                    label="Calendario"
                    options={actualYearSelect}
                    onChange={handleChangeTable}
                    // defaultValue={dateObj.year}
                  />
                </Grid>
              </Grid>
            </form>
          </div>

          <br className="br-white-space" />

          <SimpleTable
            //data={paiInfo}
            data={dasboardBioMedicalBurnData.data}
            columns={dasboardBioMedicalBurnData.columns}
            // scrollHeight={"300px"}
            text={"Realizar Autoevaluación"}
            isPaginated={true}
            sortable
            buttons={[
              {
                type: "icon",
                icon: icons.eye,
                onClick: () => {
                  console.log("redirigir al PAI con el id");
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default BioMedicalRequest;
