import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { CustomTabPanel } from "../../../components/tabComponent";
import SimpleSelect from "../../../components/selects/simpleSelect";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../constants";
import { months } from "../../../constants/selectData";
import SimpleTable from "../../../components/tables/simpleTable";
import { stakedColumnChartOptions } from "../../../components/highchartsComponent/charts/stackedColumn";
import {
  getCurrentMonth,
  getCurrentYear,
  getMonthName,
} from "../../../utils/dateUtils";
import GraphSection from "../graphSection";
import {
  callApiAsync,
  getCallOpportunitiesResume,
} from "../../../services/apiService";
import { canEditByAuthority } from "./authorizeFunctions";
import { actualYearSelect } from "../../../services/dateService";

const OpportunityTab = ({ userId, userData }) => {
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [extraData, setExtraData] = useState({
    month: getCurrentMonth(),
    year: getCurrentYear(),
  });
  const { control, getValues, setValue, watch } = useForm();
  const columnsOpportunity = [
    { field: "processName", header: "Procesos" },
    { field: "processId", header: "", columnType: "hide" },
    { field: "total", header: "Totales" },
    { field: "actions", header: "", columnType: "actionButtons" },
  ];
  const [isEditable, setIsEditable] = useState(false);

  const isEditableFn = () => {
    const canEditResult = canEditByAuthority(userData.pageList);
    if (canEditResult) {
      setIsEditable(true);
    }
  };

  useEffect(() => {
    setValue("year", getCurrentYear());
    setValue("month", getCurrentMonth());
    const initializeData = async () => {
      const formData = getValues();
      await filterData(formData.year, formData.month);
    };
    initializeData();
    isEditableFn();
  }, []);

  const handleChange = async () => {
    const formData = getValues();
    setExtraData(formData);
    await filterData(formData.year, formData.month);
  };

  const filterData = async (yearSelected, monthSelected) => {
    callApiAsync(
      () => getCallOpportunitiesResume(userId, yearSelected, monthSelected),
      (data) => {
        setDataTable(data.table);
        setDataTotal(data.totalCount);
      }
    );
  };

  return (
    <CustomTabPanel value="2">
      <div className="HContainer mb-10">
        <div className="totalRiskContainer VContainer align-center">
          <p className="totalmonthrisk-number">{dataTotal}</p>
          <p className="totalmonthrisk-label">
            Total de Oportunidades en {getMonthName(watch("month"))}{" "}
            {watch("year")}
          </p>
        </div>

        <div className="creationButtonFilterContainer">
          <div>
            {isEditable && (
              <LaddaButton
                variant="contained"
                onClick={() => {
                  navigate("/riskAndOpportunityManagement/createOpportunity");
                }}
                borderRadius={"15px"}
                width={"100%"}
                height={"40px"}
                color={"#141313"}
                margin={"0 10px 20px 10px"}
                backgroundColor={"#EFCD00"}
              >
                <div className="HContainer align-center">
                  <img
                    className="btn-icon"
                    src={icons.exclamationboard}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Crear Nueva Oportunidad</p>
                </div>
              </LaddaButton>
            )}
          </div>

          <div className="info-form-container">
            <form>
              <Grid container item xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <div className="mr-10">
                    <SimpleSelect
                      name="month"
                      control={control}
                      label="Mes"
                      options={months}
                      onChange={handleChange}
                      // defaultValue={getDateObj.month}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SimpleSelect
                    name="year"
                    control={control}
                    label="Año"
                    options={actualYearSelect}
                    onChange={handleChange}
                    // defaultValue={getDateObj.year}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>

      <SimpleTable
        tableStyle={{ minWidth: "870px" }}
        data={dataTable}
        columns={columnsOpportunity}
        buttons={[
          {
            type: "redirectData",
            icon: icons.eye,
            url: "/riskAndOpportunityManagement/opportunityAdminGestion",
            extraData: extraData,
          },
        ]}
      />

      <GraphSection
        title={"DETALLES DE OPORTUNIDADES"}
        filters={"opportunity"}
        typeGraph={stakedColumnChartOptions}
      />
    </CustomTabPanel>
  );
};

export default OpportunityTab;
