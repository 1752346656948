import React, { useEffect, useState } from "react";
import LaddaButton from "../../components/buttons/laddaButton";
import { Link, useLocation } from "react-router-dom";
import BreadCrumbComp from "../../components/breadCrumb";
import CompetenceGraphSection from "./graphSection/competenceGraph";
import ComparisonGraphSection from "./graphSection/comparisonGraph";
import Total360Graph from "./graphSection/total360Graph";
import TabComponent from "../../components/tabComponent";
import { getCookie } from "../../utils/getCookie";
import "./styles.css";
import { useSelector } from "react-redux";
import { AuthorizedComponent } from "../../components/autorizedComponent";
import { Page360report } from "../../utils/AuthorizedUtils/360Report";
import ObservationsTab from "./observations";
import {
  callApiAsync,
  getCallManagerReport360GraphData,
  getCallManagerTotal360GraphData,
  getCallOrganizationalReport360GraphData,
  getCallReport360Observations,
  getCallSpecifyReport360GraphData,
  getSettingById,
} from "../../services/apiService";

const Report_360 = () => {
  const location = useLocation();
  const { rowData } = location.state || {};

  const [observationsData, setObservationsData] = useState(null);
  const [managerReport, setManagerReport] = useState([]);
  const [managerReportTotal, setManagerReportTotal] = useState([]);
  const [specifyReport, setSpecifyReport] = useState([]);
  const [organizationalReport, setOrganizationalReport] = useState([]);
  const [organizationalUmbral, setOrganizationalUmbral] = useState(0);
  const [specifyUmbral, setSpecifyUmbral] = useState(0);

  const userSesion = getCookie("sesion");
  const userData = useSelector((state) => state.userData.userData);

  const getManagerReport360Graph = async () => {
    const userId = rowData?.id;
    if (userId !== undefined) {
      callApiAsync(
        () => getCallManagerReport360GraphData(userId),
        (data) => {
          setManagerReport(data);
        }
      );
    }
  };

  const getManagerTotal360Graph = async () => {
    const userId = rowData?.id;
    if (userId !== undefined) {
      callApiAsync(
        () => getCallManagerTotal360GraphData(userId),
        (data) => {
          setManagerReportTotal(data);
        }
      );
    }
  };

  const getSpecifyReport360Graph = async () => {
    const userId = rowData?.id;
    if (userId !== undefined) {
      callApiAsync(
        () => getCallSpecifyReport360GraphData(userId),
        (data) => {
          setSpecifyReport(data);
        }
      );
    }
  };

  const getOrganizationalReport360Graph = async () => {
    const userId = rowData?.id;
    if (userId !== undefined) {
      callApiAsync(
        () => getCallOrganizationalReport360GraphData(userId),
        (data) => {
          setOrganizationalReport(data);
        }
      );
    }
  };

  const getObservationsData = async () => {
    const userId = rowData?.id;
    if (userId !== undefined) {
      callApiAsync(
        () => getCallReport360Observations(userId),
        (data) => {
          setObservationsData(data);
        }
      );
    }
  };

  const getUmbral = async (settingId, stateSet) => {
    const userId = rowData?.id;
    if (userId == undefined) return;

    callApiAsync(
      () => getSettingById(settingId),
      (data) => {
        const settingValue = data == null || data == undefined ? 0 : data;
        stateSet(settingValue);
      }
    );
  };

  useEffect(() => {
    getManagerReport360Graph();
    getManagerTotal360Graph();
    getSpecifyReport360Graph();
    getOrganizationalReport360Graph();
    getObservationsData();
    getUmbral("SPECIFIC_COMPETENCY_THRESHOLD", setSpecifyUmbral);
    getUmbral("ORGANIZATIONAL_COMPETENCY_THRESHOLD", setOrganizationalUmbral);
  }, []);

  const tabData = [
    {
      name: "Observaciones",
      id: "0",
      content: (
        <>
          <ObservationsTab
            observationsData={observationsData}
            getObservationsData={getObservationsData}
            userIdSelected={rowData?.id}
            userData={userData}
          />
        </>
      ),
    },
    {
      name: "Específicas",
      id: "1",
      content: (
        <>
          <div>
            <p className="graph_title_report_360">Competencia</p>
            <CompetenceGraphSection
              type={0}
              data={specifyReport}
              umbral={specifyUmbral}
            />
          </div>
          <AuthorizedComponent
            allowedAuthority={[Page360report.specificComparative]}
            userAuthority={userData.authorityList}
          >
            <div className="table-container">
              <p className="graph_title_report_360">Comparativa</p>
              <ComparisonGraphSection
                type={0}
                data={managerReport}
                umbral={specifyUmbral}
              />
            </div>
          </AuthorizedComponent>

          <AuthorizedComponent
            allowedAuthority={[Page360report.specificTotal360]}
            userAuthority={userData.authorityList}
          >
            <div className="table-container">
              <p className="graph_title_report_360">Total 360</p>
              <Total360Graph
                type={0}
                data={managerReportTotal}
                umbral={specifyUmbral}
              />
            </div>
          </AuthorizedComponent>
        </>
      ),
    },
    {
      name: "Organizacionales",
      id: "2",
      content: (
        <>
          <div>
            <p className="graph_title_report_360">Competencia</p>
            <CompetenceGraphSection
              type={1}
              data={organizationalReport}
              umbral={organizationalUmbral}
            />
          </div>

          <AuthorizedComponent
            allowedAuthority={[Page360report.organizationalComparative]}
            userAuthority={userData.authorityList}
          >
            <div className="table-container">
              <p className="graph_title_report_360">Comparativa</p>
              <ComparisonGraphSection
                type={1}
                data={managerReport}
                umbral={organizationalUmbral}
              />
            </div>
          </AuthorizedComponent>

          <AuthorizedComponent
            allowedAuthority={[Page360report.organizationalTotal360]}
            userAuthority={userData.authorityList}
          >
            <div className="table-container">
              <p className="graph_title_report_360">Total 360</p>
              <Total360Graph
                type={1}
                data={managerReportTotal}
                umbral={organizationalUmbral}
              />
            </div>
          </AuthorizedComponent>
        </>
      ),
    },
  ];

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1 className="page-title">
          INFORME 360° - {rowData?.employee ? rowData.employee : ""}
        </h1>
        <div className="HContainer">
          <div className="HContainer">
            <Link to="/superior_rol_dashboard">
              <LaddaButton
                variant="contained"
                color={"black"}
                backgroundColor={"#F4D658"}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Dashboard</p>
                </div>
              </LaddaButton>
            </Link>
          </div>
        </div>
      </div>

      <h1 className="title_report_360">
        Resultados Autoevaluación y Evaluadores
      </h1>

      <TabComponent panels={tabData} />
    </div>
  );
};

export default Report_360;
