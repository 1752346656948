import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import InspectionActBanner from "../../components/topBanners/InspectionActBanner";
import FormTextField from "../../components/textField/formTextField";
import { Grid, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import InspectionProtocol from "./technicalInspectionComponents/inspectionProtocol";
import AnnexesPhotographicEvidence from "./technicalInspectionComponents/AnnexesPhotographicEvidence";
import ElectronicSignature from "./technicalInspectionComponents/electronicSignature";
import LaddaButton from "../../components/buttons/laddaButton";
import {
  callActionApiAsync,
  callApiAsync,
  getCallBioEquipmentById,
  postRespondInspectionRecord,
  postSaveInspectionRecord
} from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { isRoleUtil } from "../../utils/AuthorizedUtils/360Report";
import ModalComponent from "../../components/modals";
import DeclineInspectionRecordModal from "./technicalInspectionComponents/declineInspectionRecordModal";
import { inspectionRecordProcess } from "../../constants/enums";
import { useNavigate } from "react-router-dom";
import ToastNotification from "../../components/toastNotification";

const urlApi = process.env.REACT_APP_API_URL;

const NewTechnicalInspectionAct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userData.userData);
  const bioEquipmentId = useSelector((state) => state.biomedical.biomedicalId);
  var isApprover = isRoleUtil(
    "APPROVER_TECHNICAL_INSPECTION",
    userData.roleList
  );

  var isBiomedicalCreator = isRoleUtil("BIOMEDICAL_MODULE", userData.roleList);
  var canCreateAndApprove = isApprover && isBiomedicalCreator;
  var canEdit = isBiomedicalCreator && !isApprover;

  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [signature, setSignature] = useState(null);
  const [inspectionTitle, setInspectionTitle] = useState(
    "CREAR NUEVA ACTA DE INSPECCIÓN TÉCNICA EQUIPOS BIOMÉDICOS"
  );
  const [loadDataState, setLoadDataState] = useState({});
  const [protocolData, setProtocolData] = useState([]);
  const [images, setImages] = useState({
    image1: { file: null, description: "", path: null, id: 0, isNew: false },
    image2: { file: null, description: "", path: null, id: 0 },
    image3: { file: null, description: "", path: null, id: 0 },
    image4: { file: null, description: "", path: null, id: 0 }
  });
  const [notificationDataModal, setNotificationDataModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    severity: "warning",
    summary: "",
    detail: ""
  });
  const [approverSignature, setApproverSignature] = useState("");
  const [approverData, setApproverData] = useState({});

  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = useForm({
    mode: "all"
  });

  const { control: notificationControl, setValue: notificationSetValue } =
    useForm({
      defaultValues: {
        coment: ""
      }
    });

  const handleCloseDeclineModal = () => {
    setOpenDeclineModal(false);
  };

  const getBioEquipmentById = () => {
    callApiAsync(
      () => getCallBioEquipmentById(bioEquipmentId),
      (data) => {
        setLoadDataState(data);
        if (data.inspectionName) {
          setInspectionTitle(
            "ACTA DE INSPECCIÓN TÉCNICA EQUIPOS BIOMÉDICOS " +
              data.inspectionName
          );
        } else {
          setInspectionTitle(
            "CREAR NUEVA ACTA DE INSPECCIÓN TÉCNICA EQUIPOS BIOMÉDICOS"
          );
        }
        loadData(data);
        notificationSetValue("coment", data.comments);
      }
    );
  };
  const getTextApprovation = () => {
    if (
      inspectionRecordProcess.pending == loadDataState.state ||
      inspectionRecordProcess.sent == loadDataState.state
    )
      return "Pendiente de Aprobacion";
    if (inspectionRecordProcess.approved == loadDataState.state)
      return "Aprovado";
    return "Pendiente de Aprobacion";
  };
  const loadData = (inspectionActData) => {
    setIsEditable(
      inspectionActData.state == inspectionRecordProcess.sent ||
        inspectionActData.state == inspectionRecordProcess.approved
    );
    setValue("equipment", inspectionActData.name);
    setValue("actBranch", inspectionActData.brandName);
    setValue("model", inspectionActData.model);
    setValue("serie", inspectionActData.serieName);
    setValue(
      "importDeclaration",
      inspectionActData.importDeclaration
        ? inspectionActData.importDeclaration
        : ""
    );
    setValue("observations", inspectionActData.observations);
    setSignature(inspectionActData.signature);

    let finalImageUrl1 = "";
    let finalImageUrl2 = "";
    let finalImageUrl3 = "";
    let finalImageUrl4 = "";

    if (inspectionActData.path1) {
      const correctedImagePath1 = inspectionActData.path1.replace(/\\/g, "/");
      finalImageUrl1 = `${urlApi}/${correctedImagePath1}`;
    }

    if (inspectionActData.path2) {
      const correctedImagePath2 = inspectionActData.path2.replace(/\\/g, "/");
      finalImageUrl2 = `${urlApi}/${correctedImagePath2}`;
    }

    if (inspectionActData.path3) {
      const correctedImagePath3 = inspectionActData.path3.replace(/\\/g, "/");
      finalImageUrl3 = `${urlApi}/${correctedImagePath3}`;
    }

    if (inspectionActData.path4) {
      const correctedImagePath4 = inspectionActData.path4.replace(/\\/g, "/");
      finalImageUrl4 = `${urlApi}/${correctedImagePath4}`;
    }

    setImages({
      image1: {
        file: null,
        path: inspectionActData.path1 ? finalImageUrl1 : null,
        description: inspectionActData.description1 || ""
      },
      image2: {
        file: null,
        path: inspectionActData.path2 ? finalImageUrl2 : null,
        description: inspectionActData.description2 || ""
      },
      image3: {
        file: null,
        path: inspectionActData.path3 ? finalImageUrl3 : null,
        description: inspectionActData.description3 || ""
      },
      image4: {
        file: null,
        path: inspectionActData.path4 ? finalImageUrl4 : null,
        description: inspectionActData.description4 || ""
      }
    });

    const signaturePath = inspectionActData.approverSignature.replace(
      /\\/g,
      "/"
    );
    setApproverSignature(`${urlApi}/${signaturePath}`);
    setApproverData({
      approverUserName: inspectionActData.approverUserName,
      approverPositionName: inspectionActData.approverPositionName
    });
  };

  useEffect(() => {
    getBioEquipmentById();
  }, [setValue]);

  async function blobUrlToFile(blobUrl, index) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], bioEquipmentId + "_" + index + "image.jpg", {
      type: blob.type
    });
  }

  const onSubmit = async () => {
    if (signature == null) {
      setToastConfig({
        severity: "error",
        summary: "Error al cargar firma",
        detail: "Debes guardar la firma antes de guardar el acta."
      });
      setShowToast(true);
      return;
    }
    const formData = new FormData();
    if (images.image1.file || images.image1.path == null) {
      formData.append("delete1", true);
    } else {
      formData.append("delete1", false);
    }
    if (images.image2.file || images.image2.path == null) {
      formData.append("delete2", true);
    } else {
      formData.append("delete2", false);
    }
    if (images.image3.file || images.image3.path == null) {
      formData.append("delete3", true);
    } else {
      formData.append("delete3", false);
    }
    if (images.image4.file || images.image4.path == null) {
      formData.append("delete4", true);
    } else {
      formData.append("delete4", false);
    }

    if (images.image1.path && images.image1.file != null) {
      const imageFile = await blobUrlToFile(images.image1.path, 1);
      formData.append("image1", imageFile);
    }
    if (images.image2.path && images.image2.file != null) {
      const imageFile = await blobUrlToFile(images.image2.path, 2);
      formData.append("image2", imageFile);
    }
    if (images.image3.path && images.image3.file != null) {
      const imageFile = await blobUrlToFile(images.image3.path, 3);
      formData.append("image3", imageFile);
    }
    if (images.image4.path && images.image4.file != null) {
      const imageFile = await blobUrlToFile(images.image4.path, 4);
      formData.append("image4", imageFile);
    }

    const observations = getValues("observations") || "";

    formData.append("description1", images.image1.description);
    formData.append("description2", images.image2.description);
    formData.append("description3", images.image3.description);
    formData.append("description4", images.image4.description);
    formData.append("biomedical_equipment_id", bioEquipmentId);
    formData.append("signature", signature);
    formData.append("userId", userData.id);
    formData.append("observations", observations);
    formData.append("importDeclaration", getValues("importDeclaration"));
    formData.append(
      "inspectionRecordId",
      loadDataState.inspectionRecordId == null
        ? 0
        : loadDataState.inspectionRecordId
    );
    callApiAsync(
      () => postSaveInspectionRecord(formData),
      (data) => {
        getBioEquipmentById();
      }
    );
  };

  const onSubmitAnswer = () => {
    const objToSend = {
      biomadicalEquipmentId: bioEquipmentId,
      inspectionRecordId: loadDataState.inspectionRecordId,
      state: inspectionRecordProcess.approved,
      approverSignature: "",
      userId: userData.id
    };
    callActionApiAsync(
      () => postRespondInspectionRecord(objToSend),
      () => {
        setToastConfig({
          severity: "success",
          summary: "Aprobación",
          detail: "Inspección técnica aprobada correctamente."
        });
        setShowToast(true);
        getBioEquipmentById();
      },
      () => {
        setToastConfig({
          severity: "error",
          summary: "Error de aprobación",
          detail: "Debes tener una firma relacionada para aprobar."
        });
        setShowToast(true);
      }
    );
  };

  return (
    <div>
      <BreadCrumbComp />
      <InspectionActBanner />
      <div className="create-form-container">
        <div className="HContainer j-space-between mv-25 a-center">
          <p className="createRist-title">{inspectionTitle}</p>
          {loadDataState.comments && (
            <LaddaButton
              width={"160px"}
              fontSize={"12px"}
              height={"40px"}
              variant="contained"
              backgroundColor={"#FFA500"}
              onClick={() => {
                notificationSetValue("coment", loadDataState.comments);
                setNotificationDataModal(true);
              }}
            >
              <p className="button_text">Comentario</p>
            </LaddaButton>
          )}
        </div>

        <p className="createRist-title mt-25">1. Tecnología Médica</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="create-label">Equipo:</p>
              <FormTextField
                name="equipment"
                control={control}
                label=""
                error={errors.equipment}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Marca:</p>
              <FormTextField
                name="actBranch"
                control={control}
                label=""
                error={errors.actBranch}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="create-label">Modelo:</p>
              <FormTextField
                name="model"
                control={control}
                label=""
                error={errors.model}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Serie:</p>
              <FormTextField
                name="serie"
                control={control}
                label=""
                error={errors.serie}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Declaración de Importación:</p>
              <FormTextField
                name="importDeclaration"
                control={control}
                label=""
                error={errors.importDeclaration}
                disabled={!canEdit && !canCreateAndApprove}
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>

          <InspectionProtocol
            initialProtocolData={protocolData}
            bioEquipmentId={bioEquipmentId}
            isApprover={
              isApprover && !isBiomedicalCreator && !canCreateAndApprove
            }
          />

          <AnnexesPhotographicEvidence
            images={images}
            setImages={setImages}
            isApprover={
              isApprover && !isBiomedicalCreator && !canCreateAndApprove
            }
          />

          <div className="page-break">
            <p className="createRist-title mt-25">4. Observaciones</p>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <p className="create-label">Observaciones:</p>
                <FormTextField
                  name="observations"
                  control={control}
                  label=""
                  isTextArea={true}
                  error={errors.observations}
                  maxLength={500}
                  disabled={
                    isApprover && !isBiomedicalCreator && !canCreateAndApprove
                  }
                />
              </Grid>
            </Grid>
          </div>

          <ElectronicSignature
            setSignature={setSignature}
            base64Signature={signature}
            isApprover={
              isApprover && !isBiomedicalCreator && !canCreateAndApprove
            }
          />
          <div
            className={`info-container ${
              approverSignature && !isMediumScreen ? "approver-with-margin" : ""
            }`}
          >
            <Grid
              container
              justifyContent="center"
              spacing={2}
              sx={{
                alignItems: "flex-end"
              }}
            >
              <Grid item xs={12} md={6}>
                <div className="user-info-box">
                  <p>
                    <strong>Nombre:</strong> {loadDataState.userName}
                  </p>
                  {loadDataState.positionName && (
                    <p>
                      <strong>Cargo:</strong> {loadDataState.positionName}
                    </p>
                  )}
                  <p
                    className="text-form-label"
                    style={{ textAlign: "center" }}
                  >
                    Seranest Pharma LTDA
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div
                  className="approval-status-box"
                  style={{
                    backgroundColor:
                      inspectionRecordProcess.approved == loadDataState.state
                        ? "#D1FAE5"
                        : "#FEE2E2"
                  }}
                >
                  {inspectionRecordProcess.approved ? (
                    <div className="mt-10">
                      <div>
                        <img
                          src={approverSignature}
                          style={{
                            width: "100%",
                            objectFit: "contain"
                          }}
                          disabled
                        />

                        <p className="approver-text">
                          <strong>
                            {approverData.approverUserName ? "Nombre: " : ""}
                          </strong>
                          {approverData.approverUserName}
                        </p>
                        <p className="approver-text">
                          <strong>
                            {approverData.approverPositionName ? "Cargo: " : ""}
                          </strong>
                          {approverData.approverPositionName}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p
                        style={{
                          color:
                            inspectionRecordProcess.approved ==
                            loadDataState.state
                              ? "#047857"
                              : "#B91C1C",
                          fontWeight: "bold"
                        }}
                      >
                        {getTextApprovation()}
                      </p>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="HContainer j-center mv-25">
            {isApprover &&
              !isBiomedicalCreator &&
              inspectionRecordProcess.sent == loadDataState.state && (
                <div className="HContainer">
                  <LaddaButton
                    width={"160px"}
                    fontSize={"12px"}
                    height={"40px"}
                    variant="contained"
                    backgroundColor={"#6A6AD8"}
                    onClick={() => {
                      onSubmitAnswer();
                    }}
                  >
                    <p className="button_text">Aprobar</p>
                  </LaddaButton>
                  <LaddaButton
                    width={"160px"}
                    fontSize={"12px"}
                    height={"40px"}
                    variant="contained"
                    backgroundColor={"#F43F5E"}
                    onClick={() => {
                      setOpenDeclineModal(true);
                    }}
                  >
                    <p className="button_text">Rechazar</p>
                  </LaddaButton>
                </div>
              )}
            {!isApprover && isBiomedicalCreator && (
              <div className="HContainer no-print">
                <LaddaButton
                  variant="contained"
                  color={"#404040"}
                  backgroundColor={"#F4D658"}
                  onClick={() => {
                    window.print();
                  }}
                >
                  <p className="button_text">Crear PDF</p>
                </LaddaButton>
                {!isEditable ? (
                  <>
                    <LaddaButton
                      width={"160px"}
                      fontSize={"12px"}
                      height={"40px"}
                      variant="contained"
                      type="submit"
                    >
                      <p className="button_text">Guardar</p>
                    </LaddaButton>
                  </>
                ) : (
                  <>
                    <LaddaButton
                      width={"160px"}
                      fontSize={"12px"}
                      height={"40px"}
                      variant="contained"
                      onClick={() => {
                        navigate("/biomedicalManagementDashboard/manageDevice");
                      }}
                    >
                      <p className="button_text">Volver</p>
                    </LaddaButton>
                  </>
                )}
              </div>
            )}
            {canCreateAndApprove && (
              <div className="HContainer no-print">
                {loadDataState.state !== inspectionRecordProcess.approved ? (
                  <>
                    <LaddaButton
                      variant="contained"
                      color={"#404040"}
                      backgroundColor={"#F4D658"}
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <p className="button_text">Crear PDF</p>
                    </LaddaButton>
                    {!isEditable ? (
                      <>
                        <LaddaButton
                          width={"160px"}
                          fontSize={"12px"}
                          height={"40px"}
                          variant="contained"
                          type="submit"
                        >
                          <p className="button_text">Guardar</p>
                        </LaddaButton>
                      </>
                    ) : (
                      <>
                        <LaddaButton
                          width={"160px"}
                          fontSize={"12px"}
                          height={"40px"}
                          variant="contained"
                          onClick={() => {
                            navigate(
                              "/biomedicalManagementDashboard/manageDevice"
                            );
                          }}
                        >
                          <p className="button_text">Volver</p>
                        </LaddaButton>
                        <LaddaButton
                          width={"160px"}
                          fontSize={"12px"}
                          height={"40px"}
                          variant="contained"
                          backgroundColor={"#6A6AD8"}
                          onClick={() => {
                            onSubmitAnswer();
                          }}
                        >
                          <p className="button_text">Aprobar</p>
                        </LaddaButton>
                        <LaddaButton
                          width={"160px"}
                          fontSize={"12px"}
                          height={"40px"}
                          variant="contained"
                          backgroundColor={"#F43F5E"}
                          onClick={() => {
                            setOpenDeclineModal(true);
                          }}
                        >
                          <p className="button_text">Rechazar</p>
                        </LaddaButton>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <LaddaButton
                      variant="contained"
                      color={"#404040"}
                      backgroundColor={"#F4D658"}
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <p className="button_text">Crear PDF</p>
                    </LaddaButton>
                    <LaddaButton
                      width={"160px"}
                      fontSize={"12px"}
                      height={"40px"}
                      variant="contained"
                      onClick={() => {
                        navigate("/biomedicalManagementDashboard/manageDevice");
                      }}
                    >
                      <p className="button_text">Volver</p>
                    </LaddaButton>
                  </>
                )}
              </div>
            )}
          </div>
        </form>
      </div>

      <ModalComponent
        open={notificationDataModal}
        handleClose={() => {
          setNotificationDataModal(false);
        }}
        title={""}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <Grid container className="mv-25 min-w-700">
          <Grid item xs={12} md={12}>
            <div className="VContainer j-center w-675">
              <p className="text-form-label" style={{ marginLeft: "10px" }}>
                Comentario:
              </p>
              <FormTextField
                name="coment"
                control={notificationControl}
                isTextArea={true}
                rows={2}
                disabled={true}
              />
            </div>
          </Grid>
        </Grid>
      </ModalComponent>
      <DeclineInspectionRecordModal
        open={openDeclineModal}
        close={handleCloseDeclineModal}
        bioEquipmentId={bioEquipmentId}
        inspectionRecordId={loadDataState.inspectionRecordId}
        comments={loadDataState.comments}
      />
      <ToastNotification
        severity={toastConfig.severity}
        summary={toastConfig.summary}
        detail={toastConfig.detail}
        showToast={showToast}
        setShowToast={setShowToast}
        position="top-right"
      />
    </div>
  );
};

export default NewTechnicalInspectionAct;
